<template>
  <div class="page-background">
    <div class="module-dashboard" v-if="settings">
      <b-button
        class="main-button webapp-button eh-action-button"
        style="margin: 20px 0px"
        v-on:click="$router.go(-1)"
        >{{ $t("base.basic.back") }}</b-button
      >

      <div class="chart-to-ordered-array-list">
        <div class="chart-list-title">
          <h2>
            {{
              $t("client.functionSettings.moduleSettings", {
                module: $t("module.modules." + settings.Name),
              })
            }}
          </h2>
        </div>
        <div class="chart-list-title">
          <h3>{{ $t("client.functionSettings.functions") }}</h3>
        </div>
        <fp-table-list
          :items="Object.entries(settings.Functions)"
          :fields="functionsFields"
        >
          <template #key="row">
            <div>
              {{ $t("module.moduleDiaries." + row.item[0]) }}
            </div>
          </template>
          <template #value="row">
            <b-form-checkbox
              class="function-setting-switch"
              v-model="row.item[1]"
              @change="changeFunctionVisible({ [row.item[0]]: row.item[1] })"
              name="check-button"
              switch
            />
            <!-- <ejs-switch
              @change="changeFunctionVisible({ [row.item[0]]: row.item[1] })"
              :checked="row.item[1]"
              v-model="row.item[1]"
              value="true"
              unchecked-value="false"
            ></ejs-switch> -->
          </template>
        </fp-table-list>
        <div class="chart-list-title">
          <h3>{{ $t("client.functionSettings.charts") }}</h3>
        </div>
        <fp-table-list
          :items="getModulesOrderedChartsArray(settings.Charts)"
          :fields="chartsSettingFields"
        >
          <template #Size="row">
            <select
              class="bootstrap-select"
              @change="changeChartSize($event.target.value, row.item.ChartName)"
              v-model="row.item.Size"
            >
              <option
                v-for="size in Object.values(chartSizeValues)"
                :key="size.Value"
                :value="size.Value"
              >
                {{ $t(size.Text) }}
              </option>
              <!--  <option :value="'Small'">
                {{ $t("client.functionSettings.smallSize") }}
              </option>
              <option :value="'Medium'">
                {{ $t("client.functionSettings.mediumSize") }}
              </option>
              <option :value="'Large'">
                {{ $t("client.functionSettings.largeSize") }}
              </option> -->
            </select>
          </template>
          <template #Interval="row">
            <select
              :disabled="row.item.isDisableInterval"
              class="bootstrap-select"
              @change="
                changeChartInterval($event.target.value, row.item.ChartName)
              "
              v-model="row.item.Interval"
            >
              <option
                v-for="interval in Object.values(chartIntervalValues)"
                :key="interval.Value"
                :value="interval.Value"
              >
                {{ $t(interval.Text) }}
              </option>
            </select>
          </template>
          <template #Enabled="row">
            <b-form-checkbox
              class="function-setting-switch"
              v-model="row.item.Enabled"
              @change="changeChartVisible($event, row.item.ChartName)"
              name="check-button"
              switch
            />
            <!-- <ejs-switch
              @change="changeChartVisible($event.checked, row.item.ChartName)"
              :checked="row.item.Enabled"
              v-model="row.item.Enabled"
              value="true"
              unchecked-value="false"
            ></ejs-switch> -->
          </template>
        </fp-table-list>

        <div class="chart-list-title">
          <h3>
            {{ $t("client.functionSettings.chartOrder") }}
            <b-icon icon="arrow-down-up" />
          </h3>
        </div>
        <div>
          <ejs-listbox
            id="chartOrderListBox"
            :dataSource="getModulesOrderedChartsArray(settings.Charts)"
            :allowDragAndDrop="true"
            :fields="fields"
            :drop="chartListChange"
          >
          </ejs-listbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { ListBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { enableRipple } from "@syncfusion/ej2-base";
import { CheckBoxPlugin } from "@syncfusion/ej2-vue-buttons";
import { UserPreferencesLogic } from "@/logic/backend/user-preferences";

Vue.use(CheckBoxPlugin);
Vue.use(ListBoxPlugin);

enableRipple(true);

export default {
  name: "FunctionSettings",
  data() {
    return {
      fields: { text: "DisplayName", iconCss: "iconCss" },
      chartsSettingFields: [
        {
          key: "DisplayName",
          label: this.$t(
            "client.functionSettings.chartsSettingFields.displayName"
          ),
        },
        {
          key: "Enabled",
          label: this.$t("client.functionSettings.chartsSettingFields.enabled"),
        },
        {
          key: "Interval",
          label: this.$t(
            "client.functionSettings.chartsSettingFields.interval"
          ),
        },
        {
          key: "Size",
          label: this.$t("client.functionSettings.chartsSettingFields.size"),
        },
      ],
      functionsFields: [
        {
          key: "key",
          label: this.$t("client.functionSettings.functionFields.key"),
        },
        {
          key: "value",
          label: this.$t("client.functionSettings.functionFields.value"),
        },
      ],
      settings: null,
      moduleName: null,
      chartIntervalValues: this.$enums.Chart.Interval,
      chartSizeValues: this.$enums.Chart.Size,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    //új metódusok
    checkQuery() {
      this.moduleName = this.$route.query.module;
      if (!this.moduleName) {
        this.$router.push({ path: "Home" });
      }
    },
    //modulok lekérése
    async getModule() {
      this.checkQuery();
      //kérés indítás
      const getModuleResponse = await UserPreferencesLogic.getUserPreferences();
      //sikeres kérés? benne van a modules?
      if (!getModuleResponse.Code && getModuleResponse.Modules) {
        //igen, a megnyitott modul adatok kiemelése
        this.settings = getModuleResponse.Modules[this.moduleName];
      } else {
        //nem, hiba üzenet
        this.$bvToast.toast(getModuleResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.settings.Name,
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      this.setHeaderbar();
    },
    setHeaderbar() {
      if (this.settings) {
        this.$emit(
          "title-changed",
          this.$t("client.functionSettings.moduleSettings", {
            module: this.$t("module.modules." + this.settings.Name),
          })
        );
        this.$emit("color-change", this.settings.Color);
      } else {
        //TODO: elirányítsuk valahova? v-else ágba informáljuk a felhasználót, hogy rossz az url?
        this.$store.dispatch(
          "setPageTitle",
          this.$t("module.modules.noModule")
        );
      }
    },
    //modulhoz tartozó chartok tömbbe rendezése megadott sorrend alapján
    getModulesOrderedChartsArray(charts) {
      //kapott chartok objektum értékeinek kiemelése
      var temp = Object.values(charts);

      temp.forEach((chart) => {
        chart.DisplayName = this.$t("module.charts." + chart.Name);
      });
      //chartok sorrendezése
      temp.sort((a, b) => a.OrderNumber - b.OrderNumber);
      //visszaadjuk a tömböt
      return temp;
    },
    async setModule(body) {
      const setModuleResponse = await UserPreferencesLogic.setModule(
        this.settings.Name,
        body
      );
      if (!setModuleResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(setModuleResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async setChart(chartName, body) {
      const setChartResponse = await UserPreferencesLogic.setChart(
        this.settings.Name,
        chartName,
        body
      );
      if (!setChartResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(setChartResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async changeFunctionVisible(diary) {
      const functions = {
        Functions: Object.assign(this.settings.Functions, diary),
      };
      await this.setModule(functions);
    },
    async changeChartSize(newSize, chartName) {
      const chart = {
        Size: newSize,
      };
      await this.setChart(chartName, chart);
    },
    async changeChartInterval(newInterval, chartName) {
      const chart = {
        Interval: newInterval,
      };
      await this.setChart(chartName, chart);
    },
    async changeChartVisible(isVisible, chartName) {
      const chart = {
        Enabled: isVisible,
      };
      await this.setChart(chartName, chart);
    },
    async chartListChange(args) {
      // setting the order numbers
      for (const value of Object.values(this.settings.Charts)) {
        const index = args.source.currentData.findIndex(
          (x) => x.Name == value.Name
        );
        value.OrderNumber = index;
      }
      await this.setModule({ Charts: this.settings.Charts });
    },
  },
  async mounted() {
    await this.getModule();
  },
};
</script>
<style>
.function-setting-switch
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: orange;
  background-color: orange;
}
.chart-to-ordered-array-list {
  width: 500px;
  margin: 0px auto 20px;
}
.chart-list-title {
  text-align: center;
  margin: 20px 0px 10px;
}
.e-list-icons {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.e-list-arrow:before {
  content: "\f338";
}
@media (max-width: 600px) {
  .chart-to-ordered-array-list {
    width: 100%;
  }
}
</style>
